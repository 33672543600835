@import '/src/scss/mixins.scss';
@import '/src/scss/variables.scss';

.header {
  position: fixed;
  z-index: 99;
  background-color: rgba($color: #ffffff, $alpha: 0.78);
  backdrop-filter: blur(0.25em);
  width: 100%;
  top: 0;
  @include box-shadow(inset 0 -0.07rem 0 0 rgba(0, 0, 0, 0.1));
}

.navbar {
  display: flex;
  flex-direction: row;
  position: relative;
  justify-content: space-between;
  align-items: center;
  @include transition(0.3s);
  
  &Collapse {
    flex-grow: 2;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .navlink {
      position: relative;
      font-weight: 600;
      color: var(--primary-greenish-blue);
      @include transition(0.3s);
      padding: 2em 1.75em; 
      position: relative;
      text-decoration: none;

      &:hover,
      &:focus-visible{
        color: var(--secondary-orange);
      }

      &::after {
        content: '';
        background-color: var(--secondary-orange);
        height: 0.2em;
        position: absolute;
        bottom: 1.8em;
        width: 0;
        left: 0;
        right: 0;
        opacity: 0;
        margin: auto;
        @include transition(0.3s);
      }
      
      &Active {
        color: var(--secondary-orange);

        &::after {
          width: 100%;
          opacity: 1;
        }
      }

      @media #{$lg} {
        padding: 2em 1em;
      }
    }

    .activeMarker {
      content: '';
      background-color: var(--secondary-orange);
      height: 0.2em;
      position: fixed;
      bottom: 1.8em;
      @include transition(0.3s);
    }

    @media #{$md, $xs} {
      flex-direction: column;
      background-color: white;
      top: 100%;
      right: 0;
      padding: 2rem;
      border-radius: 0.25rem;
      box-shadow: 0 0.6rem 1.25rem 0 rgba(0, 0, 0, 0.2);
      transform: scale(1, 0);
      transform-origin: top;
      position: absolute;
      display: flex;
      opacity: 0;
      @include transition(0.3s);

      &.active {
        opacity: 1;
        transform: scale(1, 1);
      }

      .navlink {
        padding: 0;
        margin: 0.5rem 1rem;
        width: 100%;
        text-align: left;

        &::after {
          content: '';
          background-color: var(--secondary-orange);
          height: 0.2em;
          position: absolute;
          bottom: 0em;
          width: 0;
          left: 0;
          right: initial;
          opacity: 0;
          margin: auto;
          @include transition(0.3s);
        }

        &Active {
          color: var(--secondary-orange);
  
          &::after {
            width: 100%;
            opacity: 1;
          }
        }
      }
    }
  }

  &Toggler {
    display: none;
    text-align: end;
    background: none;
    border: none;
    cursor: pointer;
    min-height: 2rem;

    span {
      width: 2rem;
      height: 0.125rem;
      background-color: var(--primary-greenish-blue);
      display: block;
      margin: 0.25rem 0;
      @include transition(0.3s);
      position: relative;;
    } 

    @media (hover: hover) {
      &:hover {
        span {
          background-color: var(--secondary-orange);
        }
      }
    }

    &:focus-visible {
      span {
        background-color: var(--secondary-orange);
      }
    }

    @media #{$md, $xs} {
      &{
        display: block;
      }
    }

    &.active {
      span {
        &:nth-of-type(1) {
          transform: rotate(45deg);
          top: 0.38rem;
        }

        &:nth-of-type(2) {
          opacity: 0;
        }

        &:nth-of-type(3) {
          transform: rotate(135deg);
          top: -0.37rem
        }
      }
    }
  }
}

.companyLogo {
  padding: 0;
  cursor: pointer;

  svg {
    height: 3rem;
  }
  
  img {
    display: block;
    max-width: 10rem;
  }

  @media #{$md, $xs} {
    &{
      padding: 0.35rem
    }
  }
}