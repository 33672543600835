@import './variables';
@import './mixins';
@import './pages.scss';

body {
  font-family: var(--font-regular), Arial;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
  margin: 0;
  line-height: 1.5;
  background-color: var(--primary-color);
}

html {
  scroll-behavior: smooth;

  .container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-left: .75rem;
    padding-right: .75rem;
    box-sizing: border-box;
    
    @media #{$xxs} {
      & {
        max-width: 100%;
      }
    }
  
    @media #{$xs} {
      & {
        max-width: 540px;
      }
    }
  
    @media #{$md} {
      & {
        max-width: 720px;
      }
    }
  
    @media #{$lg} {
      & {
        max-width: 960px;
      }
    }
  
    @media #{$xlg} {
      & {
        max-width: 1140px;
      }
    }
  
    @media #{$xxlg} {
      & {
        max-width: 1320px;
      }
    }
  }

  @media (min-width:1921px) and (max-width:2560px) {
    font-size: 21px;

    .container {
      max-width: 1760px;
    }
  }

  @media (min-width:2561px) and (max-width:2880px) {
    font-size: 24px;

    .container {
      max-width: 1980px;
    }
  }

  @media (min-width:2881px) and (max-width:3840px) {
    font-size: 32px;

    .container {
      max-width: 2640px;
    }
  }

  @media (min-width:3841px) and (max-width:4096px) {
    font-size: 34px;

    .container {
      max-width: 2816px;
    }
  }

  @media (min-width:4097px) and (max-width:6144px) {
    font-size: 51px;

    .container {
      max-width: 3754px;
    }
  }

  @media (min-width:6145px) {
    font-size: 68px;

    .container {
      max-width: 5630px;
    }
  }
}

h3 {
  font-family: var(--font-bold), Arial;
  font-size: 1.75em;
  line-height: 1.2;
  margin: 0;
}

h5 {
  font-family: var(--font-bold), Arial;
  font-size: 1.375em;
  line-height: 1.2;
  margin: 0;
}

.motion-div {
  position: absolute;
  width: 100%;
}

.route__container {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 5.4em;
  left: 0px;
  transition: 1s;
}