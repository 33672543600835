@import "/src/scss/variables.scss";

.content {
  margin-top: 5.5em;
  height: calc(100vh - 5.5em);

  @media #{$md, $xs} {
    &{
      margin-top: 3.8rem;
      height: calc(100vh - 3.8em);
    }
  }
}