
@import "/src/scss/variables.scss";

.feature {
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 1;

  img,
  img+div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.tainer {
  max-width: 100%;
  position: relative;
  box-sizing: border-box;
}

.background {
  height: 22em;
  width: 25em;
  color: white;
  position: relative;
  opacity: 0.8;
  top:0;

  @media #{$xs}{
    left: 0;
    right: 0;
    margin: auto;
    width: auto;
  }

  &::before {
    height: 22em;
    content: " ";
    position: absolute;
    background-color: inherit;
    width: 6.25em;

    @media #{$xs}{
      display: none;
    }
  }

  &::after {
    height: 22em;
    content: " ";
    position: absolute;
    background-color: inherit;
    width: 6.25em;
    z-index: -1;
    width: 16.25em;

    @media #{$xs}{
      display: none;
    }
  }

  &.right {
    margin-left: auto;
    right: 4.7em;

    @media #{$xs}{
      right: 0;
    }

    &::before {
      transform: skewX(-8deg);
      right: -2.5em;
    }

    &::after {
      transform: skewX(-25deg);
      left: -8em;
    }

  }

  &.left {
    margin-right: auto;
    left: 4.7em;

    @media #{$xs}{
      left: 0;
    }

    &::before {
      transform: skewX(8deg);
      left: -2.5em;
    }

    &::after {
      transform: skewX(25deg);
      right: -8em;
    }
  }
}

.content {
  width: 25em;
  position: absolute;
  top: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  color: var(--white);
  justify-content: center;
  text-align: left;
  padding: 1.25em;
  box-sizing: border-box;

  @media #{$xs}{
    width: 100%;
  }

  &.right {
    margin-left: auto;
    right: 6.25em;

    @media #{$xs}{
      &{
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }

  &.left {
    margin-right: auto;
    left: 6.25em;

    @media #{$xs}{
      &{
        left: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.description {
  margin-top: 0.75em;
  text-align: justify;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.buttons {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1.25em;
  font-size: 0.75em;
  text-transform: uppercase;
}
