:root {
  --font-regular: "Hexagon-Akkurat-Regular";
  --font-bold: "Hexagon-Akkurat-Bold";
  --font-black: "Hexagon-Akkurat-Black";
  --body-color: #E6E7E8;
  --primary-color: #E6E7E8;
  --white: #ffffff;
  --primary-greenish-blue: #0097BA;
  --primary-aqua: #85CDDB;
  --primary-green: #A5D867;
  --secondary-blue: #005072;
  --secondary-green: #509E2F;
  --secondary-orange: #ED8B00;
  --gray: #B6B6B6;
  --gradient-degree: 70deg;
  --gradient-primary-aqua--secondary-blue: var(--primary-aqua) 0%, var(--secondary-blue) 65%;
  --gradient-primary-green--primary-greenish-blue: var(--primary-green) 0%, var(--primary-greenish-blue) 65%;
  --gradient-primary-green--primary-blue: var(--primary-green) 0%, var(--primary-greenish-blue) 65%;
}

$xxlg: "(min-width: 1400px) and (max-width: 1920px)";
$xlg: "(min-width: 1200px) and (max-width: 1399px)";
$lg: "(min-width: 992px) and (max-width: 1199px)";
$md: "(min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$xxs: "(max-width: 576px)";
$fold-size: "(max-width: 290px)";

