@import "/src/scss/variables.scss";

.hero {
  max-width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 1;

  img, 
  img+div{
    position: absolute;
    width:100%;
    height: 100%;
    object-fit: cover;
  }
}

.background {
  height: 38em;
  width: 100%;
  position: relative;
}

.content {
  position: absolute;
  text-align: left;
  top: 1em;
  left: 0;
  color: white;
  right: 0;
  margin: auto;
  font-size: 3.75em;
  line-height: 1.5;
  text-shadow: 0 0 0.1em black;
  font-family: var(--font-bold), Arial;

  @media #{$xxs} {
    font-size: 14.5vw;
  }
}

.seeMoreContainer{
  width: 2.3rem !important;
  height: 2.3rem !important;
  position: absolute !important;
  padding: 1rem;
  border-radius: 50rem;
  box-sizing: content-box;
  bottom: 4rem;
  right: 0;       
  left: 0;
  margin: auto;
  transition: .3s visibility ease-out, .3s opacity ease-out;
}

.hideSeeMoreMouse {
  visibility: hidden;
  opacity: 0;
}

.seeMoreArrow {
  border-bottom: .2em solid white;
  border-left: .2em solid white;
  position: absolute;
  display: block;
  height: 1.1rem;
  width: 1.1rem;
  transform: rotateZ(-45deg) skew(10deg, 10deg);
  inset: 3em 0 0;
  margin: auto;
  transition: .3s border-color ease-out, .3s transform ease-out;
  animation: seeMoreAnimation 3s ease-in-out infinite;
  -webkit-animation: seeMoreAnimation 3s ease-in-out infinite;

  &.arrowOne {
    bottom: 2em;
  }
}

@keyframes seeMoreAnimation {
  0% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(0%, 0%);
  }
  15% {
    transform: rotateZ(-45deg) skew(5deg, 5deg) translate(-30%, 30%);
  }
  30% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(-20%, 20%);
  }
  35% {
    transform: rotateZ(-45deg) skew(7deg, 7deg) translate(-30%, 30%);
  }
  100% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(0%, 0%);
  }
}

@-webkit-keyframes seeMoreAnimation {
  0% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(0%, 0%);
  }
  15% {
    transform: rotateZ(-45deg) skew(5deg, 5deg) translate(-30%, 30%);
  }
  30% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(-20%, 20%);
  }
  35% {
    transform: rotateZ(-45deg) skew(7deg, 7deg) translate(-30%, 30%);
  }
  100% {
    transform: rotateZ(-45deg) skew(10deg, 10deg) translate(0%, 0%);
  }
}

.containerParallax {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}