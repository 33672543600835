.lazyImage {
  transition: 0.3s opacity ease-out, 0.3s visibility ease-out;
  position: absolute;
}

.loadingBackground {
  background-color: #a0a0a0;
  position: absolute;
}

.loadingAnimationCroppingContainer {
  position: absolute;
}

.loadingAnimation {
  background-image: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 46%,
    rgba(255, 255, 255, 0.175) 50%,
    rgba(255, 255, 255, 0) 54%
  );
  background-repeat: no-repeat;
  -webkit-animation: loadingAnimation 1.8s linear infinite;
  animation: loadingAnimation 1.8s linear infinite;
  object-fit: cover;
  height: 100% !important;
}

@keyframes loadingAnimation {
  0% {
    transform: translateX(-150%);
  }
  100% {
    transform: translateX(150%);
  }
}
