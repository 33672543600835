@import "/src/scss/mixins.scss";

.linkButton {
  font-family: var(--font-bold), Arial;
  cursor: pointer;
  border: 0.075em solid var(--white);
  padding: 0.5em 1.1em 0.37em 1.1em;
  text-decoration: none;
  color: var(--white);
  text-transform: uppercase;
  text-align: center;
  @include transition(0.3s);


  &.dark {
    background-color: rgba(0, 80, 114, 0.7);
    border-width: 0.125em;
  }

  &:hover,
  &:active,
  &:focus-visible {
    color: var(--primary-green);
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0.4);
    border-color: var(--primary-green);
  }

}

