.arrowLink {
  margin-top: 1.5em;
  position: relative;
  padding-right: 2em;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  transition: opacity 0.3s;

  &:link,
  &:visited{
    color: var(--secondary-blue) !important;
    font-family: var(--font-bold), Arial;
    font-size: 1.25em;
  }

  &::after {
    content: "";
    background-image: url(arrow.svg);
    
    width: 1.25em;
    height: 1.25em;
    padding: 0;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    right: 0;
    bottom: 0.3em;
    float: right;
    margin-left: 0.7em;
    position: absolute;
  }

  &:hover,
  &:focus-visible {
    text-decoration: none;
    opacity: 0.6;
  }
}