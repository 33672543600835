@import "/src/scss/variables.scss";

.page {
  &-content {
    padding-top: 2em;
    padding-bottom: 2em;

    .container {
      padding: calc(2.5em + 0.75rem) calc(2.5em + 0.75rem);
      text-align: justify;

      @media #{$md, $xs} {
        &{
          padding: 0.75rem;
        }
      }
    }
  }

  &-title {
    color: var(--secondary-blue);
    font-size: 1.75em;
    font-family: var(--font-bold), Arial;
    box-sizing: border-box;
  }

  &-description {
    a {
      transition: all 0.3s ease-out 0s;
      &:link {
        text-decoration: none;
        color: var(--secondary-blue);
        font-weight: 700;
      }

      &:visited {
        color: var(--secondary-blue);
      }

      &:hover {
        color: var(--secondary-orange);
      }
    }

    .compositions-table {
      caption-side: bottom;
      border-collapse: collapse;
      empty-cells: show;

      tr {
        &:nth-child(even) {
          background-color: #00000009;
        }

        @media #{$fold-size} {
          display: flex;
          flex-direction: column;
        }
      }
      td {

        &:first-child {
          padding: 1rem 2rem;
          min-width: 11rem;
          height: 9rem;
          box-sizing: border-box;
          position: relative;

          @media #{$fold-size} {
            height: 13rem;
          }

          div,
          img {
            width: 7rem;
            height: 7rem;
            transition: .3s box-shadow ease-out;
            top: 0;
            bottom: 0;
            margin: auto;

            @media #{$fold-size} {
              width: 9rem;
              height: 9rem;
              left: 0;
              right: 0;
            }
          }

          a:hover img,
          a:focus-visible img {
            box-shadow: 0 0 .4rem 0 #00000090;
          }
        }

        &:nth-child(2) {
          border-left: 1px solid var(--gray);

          @media #{$fold-size} {
            border-left: none;
          }
        }

        @media #{$md, $xs} {
          &:first-child {
            padding: 0.25rem 0.75rem;
            min-width: 9rem;
          }
        }

        &:nth-child(2) {
          padding-left: 2rem;
          padding-right: 2rem;

          @media #{$md, $xs} {
            &{
              padding: .3rem .75rem;
            }
          }
        }
      }
    }

    ul {
      margin-top: 0;
    }

    ol {
      margin-top: 0;

      li {
        position: relative;
      }
    }
  }
}

.walkthrough-separator {
  content: "";
  border-bottom: 1px solid #a0a0a0;
  width:100%;
  height: 1px;
  margin-bottom: 2em;
}

.walkthrough_image-container {
  box-sizing: border-box;
  width: 100%;
  padding: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  
  img {
    margin-top: .2em;
    position: absolute;
    width: 80%;
    height: 90%;
    display: block;
    inset: 0;
    margin: auto;
  }

  div { //lazyLoadingDiv
    width: 80%;
    height: 90%;
    position: absolute;
  }
}

.municipality {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @media #{$fold-size} {
    img {
      left: 0;
      right: 0;
      margin: auto;
    }
    div{
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  &_ortophoto-and-blank {
    height: 8rem;
  }

  &_legend-button {
    height: 6rem;
  }

  &_legend-window {
    height: 24rem;

    @media (max-width: 512px) {
      height: 70vw;
    }
  }
}

.externalImageUrl {
  fill: #00000060;
  height: 2rem;
  width: 2rem;
  position: absolute;
  inset: 0;
  margin: auto;
  transition: .3s fill ease-out;
  transform: translate(2.2rem, -2.2rem);

  @media #{$fold-size} { 
    transform: translate(3.2rem, -3.2rem);
  }

  a:hover & {
    fill: #FFFFFF
  }
}